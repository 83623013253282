body {
  margin: 0;
  padding: 0;
  background: url('https://i.imgur.com/JzA1so1.jpg') no-repeat center center fixed;
  background-size: cover; 
}

#root {
  height: 100%;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  display:none;
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}